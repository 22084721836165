import React, { useState } from "react"

import { Navbar, Nav } from "react-bootstrap"

function Navigation() {
  const [expanded, setExpanded] = useState(false)

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      sticky="top"
      expanded={expanded}
    >
      <div className="container weight-900">
        <Navbar.Brand href="#top" className="text-primary">
          #hartzfacts
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link onClick={() => setExpanded(false)} href="#quiz">
              Quiz
            </Nav.Link>
            <Nav.Link onClick={() => setExpanded(false)} href="#spenden">
              Spenden
            </Nav.Link>
            <Nav.Link onClick={() => setExpanded(false)} href="#newsletter">
              Kontakt
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navigation
