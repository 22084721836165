/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Navbar from "./navbar/navbar"

import "../static/fonts/NotoSerifTC-Regular-Alphabetic.woff2"
import "../static/fonts/NotoSerifTC-Regular-Alphabetic.woff"

import "../scss/style.scss"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    offset: 66,
  })
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Navbar />
        <main>{children}</main>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
